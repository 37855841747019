import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMoment from "vue-moment";
import Toasted from "vue-toasted";

import { auth } from "./services/firebase";
import { onAuthStateChanged } from "firebase/auth";

import "@/assets/css/app.css";

Vue.use(VueMoment);

Vue.use(Toasted, {
  theme: "toasted-primary",
  position: "bottom-right",
  duration: 3000,
  iconPack: "mdi",
  className: "custom-toasted-theme",
});

Vue.toasted.register(
  "optimus_error",
  (payload) => {
    if (!payload.message) {
      return "Something Went Wrong..";
    }
    return payload.message;
  },
  {
    type: "error",
    icon: "mdi-alert-circle-outline",
  }
);

Vue.toasted.register(
  "optimus_info",
  (payload) => {
    if (!payload.message) {
      return "Something Went Wrong..";
    }
    return payload.message;
  },
  {
    type: "info",
    icon: "mdi-alert-circle-outline",
  }
);

Vue.config.productionTip = false;

let app = "";
onAuthStateChanged(auth, async (user) => {
  await store.dispatch("setUser", user);
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
