import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { auth } from "../services/firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      requiresGuest: true,
    },
  },

  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
    meta: {
      requiresGuest: true,
    },
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "*",
    name: "Notfound",
    component: () => import("../views/NotFound.vue"),
  },
  // ADMIN ROUTES
  {
    path: "/admin/users",
    name: "Users",
    component: () => import("../views/admin/Users.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/customers",
    name: "Customers",
    component: () => import("../views/admin/Customers.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/airlines",
    name: "Airlines",
    component: () => import("../views/admin/Airlines.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/airports",
    name: "Airports",
    component: () => import("../views/admin/Airports.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/cabins",
    name: "Cabins",
    component: () => import("../views/admin/Cabins.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/invoices",
    name: "AdminInvoices",
    component: () => import("../views/admin/AdminInvoices.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/receipts",
    name: "AdminReceipts",
    component: () => import("../views/admin/AdminReceipts.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/creditnotes",
    name: "AdminCreditNotes",
    component: () => import("../views/admin/AdminCreditNotes.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/tickets",
    name: "AdminTickets",
    component: () => import("../views/admin/AdminTickets.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/nodues",
    name: "AdminDues",
    component: () => import("../views/admin/AdminDues.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/outstandings",
    name: "AccountStmnt",
    component: () => import("../views/admin/AccountStmnt.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/adminbasicsales",
    name: "AdminBasicSales",
    component: () => import("../views/admin/AdminBasicSales.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/admindetailedsales",
    name: "AdminDetailedSales",
    component: () => import("../views/admin/AdminDetailedSales.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/adminsalesreg",
    name: "AdminSalesReg",
    component: () => import("../views/admin/AdminSalesReg.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/admincrnotesreg",
    name: "AdminCrNotesRegister",
    component: () => import("../views/admin/AdminCrNotesReg.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/admincarriers",
    name: "AdminCarriers",
    component: () => import("../views/admin/AdminCarriers.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/admincitypairs",
    name: "AdminCityPairs",
    component: () => import("../views/admin/AdminCityPairs.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/admindestinations",
    name: "AdminDestinations",
    component: () => import("../views/admin/AdminDestinations.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  // CLIENT ROUTES
  {
    path: "/client/invoices",
    name: "Invoices",
    component: () => import("../views/client/Invoices.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/client/receipts",
    name: "Receipts",
    component: () => import("../views/client/Receipts.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/client/creditnotes",
    name: "CreditNotes",
    component: () => import("../views/client/CreditNotes.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/client/tickets",
    name: "Tickets",
    component: () => import("../views/client/Tickets.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/client/nodues",
    name: "Nodues",
    component: () => import("../views/client/NoDues.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/client/outstandings",
    name: "Outstandings",
    component: () => import("../views/client/Outstandings.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/client/basicsales",
    name: "BasicSales",
    component: () => import("../views/client/BasicSales.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/client/detailedsales",
    name: "DetailedSales",
    component: () => import("../views/client/DetailedSales.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/client/salesreg",
    name: "SalesRegister",
    component: () => import("../views/client/SalesReg.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/client/crnotesreg",
    name: "CrNotesRegister",
    component: () => import("../views/client/CrNotesReg.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/client/carriers",
    name: "Carriers",
    component: () => import("../views/client/Carriers.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/client/citypairs",
    name: "CityPairs",
    component: () => import("../views/client/CityPairs.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/client/destinations",
    name: "Destinations",
    component: () => import("../views/client/Destinations.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      !auth.currentUser ||
      !auth.currentUser.emailVerified ||
      localStorage.getItem("TOTP_TOKEN") === null
    ) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresGuest)) {
    if (
      auth.currentUser &&
      auth.currentUser.emailVerified &&
      localStorage.getItem("TOTP_TOKEN") != null
    ) {
      next({
        path: "/dashboard",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
