<template>
  <div class="landing">
    <Landing />
  </div>
</template>

<script>
import PublicLayout from "@/layouts/PublicLayout.vue";
import Landing from "@/components/Landing.vue";

export default {
  name: "Home",
  components: {
    Landing,
  },
  created() {
    this.$emit(`update:layout`, PublicLayout);
  },
};
</script>
