<template>
  <div>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app>
      <img height="60" width="100" src="img/aej-logo.png" />

      <div class="hidden-sm-and-down"></div>
      <v-spacer></v-spacer>

      <v-btn v-if="!isLoggedIn" text to="/login">
        <v-icon class="mr-2">mdi-account-key</v-icon>Login
      </v-btn>
      <v-btn v-if="isLoggedIn" text to="/dashboard">
        <v-icon class="mr-2">mdi-dashboard</v-icon>Dashboard
      </v-btn>
    </v-app-bar>

    <v-main>
      <slot />
    </v-main>

    <PublicFooter />
  </div>
</template>

<script>
import PublicFooter from "@/components/PublicFooter";
import { auth } from "../services/firebase";
import { onAuthStateChanged } from "firebase/auth";

export default {
  components: {
    PublicFooter,
  },
  data() {
    return {
      isLoggedIn: false,
    };
  },
  created() {
    onAuthStateChanged(auth, (userAuth) => {
      if (
        userAuth &&
        userAuth.emailVerified &&
        localStorage.getItem("TOTP_TOKEN")
      ) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
  },
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}
.v-btn {
  font-size: 1em;
}
</style>
