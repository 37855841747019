import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Chris' Cloud Account
// const firebaseConfig = {
//   apiKey: "AIzaSyB_E8gRC6TtxEWV72YmjUiVwLHtr8QcsTo",
//   authDomain: "aejmis-452ad.firebaseapp.com",
//   projectId: "aejmis-452ad",
//   storageBucket: "aejmis-452ad.appspot.com",
//   messagingSenderId: "855799542487",
//   appId: "1:855799542487:web:a8c8080ac33b990a047692",
// };

// AEJ Travels cloud Account
const firebaseConfig = {
  apiKey: "AIzaSyAqhh2mW11YNuCwPn617TlDOgPLHB1wV9o",
  authDomain: "aejmis-afee9.firebaseapp.com",
  projectId: "aejmis-afee9",
  storageBucket: "aejmis-afee9.appspot.com",
  messagingSenderId: "1035951149030",
  appId: "1:1035951149030:web:4f2d1c97a0bafa3dac1027",
  measurementId: "G-0P1K1F4KG5",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
