<template>
  <v-footer padless>
    <hr />
    <v-container fluid class="bg-color">
      <v-card flat tile class="text-center">
        <v-card-text class="pt-5">
          <h4>Terms & Conditions</h4>
          <p>
            Unless stated to the contrary, all material on this website is the
            copyright of AEJ Travels. Duplication or sale of all or any part of
            it is not permitted, except that material may be used under license,
            NDA and agreement for evaluation or on a professional basis.
            Permission for any other use must be obtained from AEJ Travels.
            Electronic or print copies may not be offered, whether for sale or
            otherwise, to any third party.
          </p>
          <h4>Disclaimer</h4>
          <p>
            AEJ Travels makes all reasonable efforts to ensure that the content
            of its website and software applications is accurate and up to date,
            but can accept no responsibility for omissions, errors or subsequent
            changes. AEJ Travels accept no liability for any loss or damage
            resulting, directly or indirectly, from any use of the information
            or material contained on this website or software applications.
          </p>
        </v-card-text>

        <v-card-text>
          Copyright &copy; {{ new Date().getFullYear() }} —
          <a href="https://aejtravels.com"><strong>AEJ Travels.</strong></a>
          Developed & maintained by
          <strong>Christopher Shekeran.</strong>
        </v-card-text>
      </v-card>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      socials: [
        { icon: "mdi-facebook", link: "#" },
        {
          icon: "mdi-linkedin",
          link: "#",
        },
        { icon: "mdi-instagram", link: "#" },
        { icon: "mdi-twitter", link: "#" },
      ],
    };
  },
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}
p {
  font-size: 1em;
}
.bg-color {
  background: #ffffff;
}
</style>
