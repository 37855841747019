import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    invoices: null,
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },

    CLEAR_USER(state) {
      state.user = null;
    },

    SET_INVOICES(state, payload) {
      state.invoices = payload;
    },

    CLEAR_INVOICES(state) {
      state.invoices = null;
    },
  },

  getters: {
    currentUser: (state) => {
      return state.user;
    },

    dashboardInvoices: (state) => {
      return state.invoices;
    },
  },

  actions: {
    async setUser({ commit }, user) {
      if (user === null) {
        commit("CLEAR_USER");
      } else {
        commit("SET_USER", user);
      }
    },

    async setInvoices({ commit }, payload) {
      if (payload === null) {
        commit("CLEAR_INVOICES");
      } else {
        commit("SET_INVOICES", payload);
      }
    },
  },
  modules: {},
});
